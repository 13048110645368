
























import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class NotFound extends Vue {
  goBack() {
    this.$router.back();
  }
  openEntryLink() {
    window.open(process.env.VUE_APP_ENTRY_URL, "_self");
  }
}
