import { render, staticRenderFns } from "./NotFound.vue?vue&type=template&id=c6e5e634&scoped=true&"
import script from "./NotFound.vue?vue&type=script&lang=ts&"
export * from "./NotFound.vue?vue&type=script&lang=ts&"
import style0 from "./NotFound.vue?vue&type=style&index=0&id=c6e5e634&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6e5e634",
  null
  
)

export default component.exports